import React, { useState, useEffect } from "react";
import { DateTime, Info } from "luxon";
import { navigate } from "gatsby";
import QuiltedImageList, {
  FlatImageList,
  FlatImageListSlide,
  itemData,
} from "./upcomingMeals";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CALENDARS } from "../../graphql/calendar";
import { GET_RECIPES } from "../../graphql/recipes";
import {
  makeYesterdayTodayTomorrow,
  fillDays,
  calculateUpcoming,
} from "../../utils/fns";
import { getUser, isLoggedIn } from "../../services/auth";
import Button from "@mui/material/Button";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
export function SlideShow({ upcoming, recipes }) {
  const [randomSlides, setRandomSlides] = useState([]);
  let breakfast = [];
  let maincourse = [];
  let slides;

  recipes.forEach((s) => {
    if (s.recipe_tags.find((t) => t.id == "20")) {
      breakfast.push(s);
    } else {
      maincourse.push(s);
    }
  });
  if (DateTime.now().toFormat("HH") < 12) {
    slides = breakfast;
  }else{
    slides = maincourse;
  }

  function setSlides() {
    if (
      upcoming.length > 0 &&
      (randomSlides.length == 0 || randomSlides.length == 10)
    ) {
      let s = upcoming.reduce(
        (t, i) => [
          ...t,
          ...i.meals.map((s) => {
            return { src: s.imageSrc, id: s.id };
          }),
        ],
        []
      );
      setRandomSlides(s);
    } else {
      let slide = [];
      for (let index = 0; index < 10; index++) {
        let rId = randomIntFromInterval(1, slides.length);
        if (slides.hasOwnProperty(rId))
          slide.push({ src: slides[rId].imageSrc, id: slides[rId].id });
      }
      setRandomSlides(slide);
    }
  }

  useEffect(() => {
    setSlides();
  }, []);

  useEffect(() => {
    const interval = setTimeout(() => {
      setSlides();
    }, 1000 * 60 * 5);
    return () => clearTimeout(interval);
  }, [randomSlides]);

  return (
    <div>
      <FlatImageListSlide data={randomSlides} />
    </div>
  );
}

function makePhotoCarousel(list) {
  return list.map((l, i) => {
    if (i < itemData.length) {
      return { ...itemData[i], img: list[i] };
    }
    return { ...itemData[0], img: list[i] };
  });
}

export default function Index() {
  const user = getUser();
  const [fullscreen, setFullscreen] = React.useState(false);

  const { data: cDatas } = useQuery(GET_CALENDARS, {
    variables: { uid: user.id },
  });
  // const { data:recipesBreakfast } = useQuery(GET_RECIPES, {
  //   variables: {
  //     input: { recipe_tags: { id: "20" } },
  //   },
  // });
  const { data: recipes } = useQuery(GET_RECIPES);

  if (!cDatas) return null;
  let [yesterday, today, tomorrow] = makeYesterdayTodayTomorrow(cDatas);
  yesterday = fillDays(cDatas, yesterday);
  today = fillDays(cDatas, today);
  tomorrow = fillDays(cDatas, tomorrow);
  const upcoming = calculateUpcoming(today, tomorrow);

  // console.log(yesterday);

  function renderDaysMenus(menulist, name) {
    if (menulist.length == 0) return null;
    if (menulist.length > 0) {
      return (
        <div style={{ marginRight: 30, marginBottom: 30 }}>
          <h5>{name}</h5>
          {/* <QuiltedImageList
                data={makePhotoCarousel(
                  yesterday.reduce(
                    (t, i) => [
                      ...t,
                      ...i.meals.map((s) => {
                        return { src: s.imageSrc, id: s.id };
                      }),
                    ],
                    []
                  )
                )}
              /> */}
          <ImageList sx={{ width: 400, height: 300 }} cols={2} gap={1}>
            {menulist
              .reduce(
                (t, i) => [
                  ...t,
                  ...i.meals.map((s) => {
                    return {
                      imageSrc: s.imageSrc,
                      id: s.id,
                      title: s.title,
                      recipeTitle: s.recipeTitle,
                    };
                  }),
                ],
                []
              )
              .map((item, j) => (
                <ImageListItem
                  key={"recipes" + item.id + j}
                  onClick={(event) => {
                    event.preventDefault();
                    navigate(`/app/recipe/` + item.id);
                  }}
                >
                  <img
                    src={`${item.imageSrc}?w=248&fit=crop&auto=format`}
                    srcSet={`${item.imageSrc}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={item.title}
                    subtitle={item.recipeTitle}
                  />
                </ImageListItem>
              ))}
          </ImageList>
          {menulist.map((y, i) => {
            return (
              <div
                key={i}
                style={{ marginBottom: 10 }}
                onClick={() => navigate("/app/mealplans/edit/" + y.groupId)}
              >
                <h6 style={{ marginBottom: 5, fontWeight: "bold" }}>
                  Meal Plan: {y.title} : {y.time?.name} (
                  {DateTime.fromISO(y.time?.time).toFormat("DD hh:mm a")})
                </h6>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {y.meals.map((k, j) => {
                    return (
                      <div
                        key={j}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <img
                          src={`${k.imageSrc}?w=248&fit=crop&auto=format`}
                          srcSet={`${k.imageSrc}?w=248&fit=crop&auto=format&dpr=2 2x`}
                          alt=""
                          loading="lazy"
                          style={{
                            width: 20,
                            height: 20,
                            marginRight: 10,
                          }}
                        />
                        <div
                          style={{
                            width: 250,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: 16,
                          }}
                        >
                          {k.title}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  }

  return (
    <>
      {upcoming.length > 0 ? (
        <div style={{ marginBottom: 50 }}>
          <h4>
            Upcoming Meal: {upcoming[0].time?.name} (
            {DateTime.fromISO(upcoming[0].time?.time).toFormat("DD hh:mm a")})
          </h4>
          {/* <FlatImageList
            data={upcoming.reduce(
              (t, i) => [
                ...t,
                ...i.meals.map((s) => {
                  return { src: s.imageSrc, id: s.id };
                }),
              ],
              []
            )}
          /> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <ImageList sx={{ width: 400, height: 300, mr: 5 }} cols={2} gap={1}>
              {upcoming
                .reduce(
                  (t, i) => [
                    ...t,
                    ...i.meals.map((s) => {
                      return {
                        imageSrc: s.imageSrc,
                        id: s.id,
                        title: s.title,
                        recipeTitle: s.recipeTitle,
                      };
                    }),
                  ],
                  []
                )
                .map((item) => (
                  <ImageListItem
                    key={item.id + "recipes-up"}
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(`/app/recipe/` + item.id);
                    }}
                  >
                    <img
                      src={`${item.imageSrc}?w=248&fit=crop&auto=format`}
                      srcSet={`${item.imageSrc}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.title}
                      loading="lazy"
                    />
                    <ImageListItemBar
                      title={item.title}
                      subtitle={item.recipeTitle}
                    />
                  </ImageListItem>
                ))}
            </ImageList>
            <div>
              {upcoming.map((y, i) => {
                return (
                  <div
                    key={i}
                    style={{ marginBottom: 10 }}
                    onClick={() => navigate("/app/mealplans/edit/" + y.groupId)}
                  >
                    <h6 style={{ marginBottom: 5, fontWeight: "bold" }}>
                      Meal Plan: {y.title} : {y.time?.name} (
                      {DateTime.fromISO(y.time?.time).toFormat("DD hh:mm a")})
                    </h6>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {y.meals.map((k, j) => {
                        return (
                          <div
                            key={j}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <img
                              src={`${k.imageSrc}?w=248&fit=crop&auto=format`}
                              srcSet={`${k.imageSrc}?w=248&fit=crop&auto=format&dpr=2 2x`}
                              alt=""
                              loading="lazy"
                              style={{
                                width: 20,
                                height: 20,
                                marginRight: 10,
                              }}
                            />
                            <div
                              style={{
                                width: 250,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontSize: 16,
                              }}
                            >
                              {k.title}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ marginRight: 50 }}>
          <h4>No upcoming meals</h4>{" "}
        </div>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // flexWrap: "wrap",
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {/* {renderDaysMenus(yesterday, "Yesterday's Menu")} */}
          {renderDaysMenus(today, "Today's Menu")}
          {renderDaysMenus(tomorrow, "Tomorrow's Menu")}
        </div>
      </div>

      <Button
        onClick={(event) => {
          event.preventDefault();
          document.body.requestFullscreen();
          setFullscreen(true);
        }}
        sx={{ my: 2, display: "block" }}
      >
        Go Fullscreen
      </Button>

      {fullscreen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "#000",
            zIndex: 1000,
          }}
        >
          <Button
            onClick={(event) => {
              event.preventDefault();
              if (document.fullscreenElement) document?.exitFullscreen();
              setFullscreen(false);
            }}
            sx={{ my: 2, display: "block" }}
          >
            Close
          </Button>
          <SlideShow upcoming={today} recipes={recipes.recipes} />
        </div>
      )}
    </>
  );
}
