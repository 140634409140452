import React from "react";
import { Link, navigate, graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import "../assets/css/main.css";
import { isLoggedIn } from "../services/auth";
import { useQuery } from "@apollo/client";
import { GET_RECIPES } from "../graphql/recipes";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Index from "../components/Dashboard/index";

const IndexPage = (props) => {
  const data = useStaticQuery(query);
  const { data: rData } = useQuery(GET_RECIPES);
  if (!rData) return null;

  return (
    <Layout seo={data.strapiHomepage.seo}>
      <div className="">
        <div className="uk-container uk-container-large">
          {!isLoggedIn() && <h1>{data.strapiHomepage.hero.title}</h1>}
          {/* <h1>Hello {isLoggedIn() ? getUser().name : "world"}!</h1> */}
          <p>
            {!isLoggedIn() && (
              <>
                You should <Link to="/app/login">log in</Link> to see restricted
                content
              </>
            )}
          </p>

          {isLoggedIn() && <Index />}

          <h4>Last added recipes</h4>
          <div
            style={{
              overflowY: "scroll",
            }}
          >
            <ImageList sx={{ width: 1600, height: 200 }} cols={8} gap={1}>
              {rData.recipes
                .slice(-8)
                .reverse()
                .map((item) => (
                  <ImageListItem
                    key={item.id + "recipes"}
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(`/app/recipe/` + item.id);
                    }}
                  >
                    <img
                      src={`${item.imageSrc}?w=248&fit=crop&auto=format`}
                      srcSet={`${item.imageSrc}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.title}
                      loading="lazy"
                    />
                    <ImageListItemBar
                      title={item.title}
                      subtitle={item.recipeTitle}
                    />
                  </ImageListItem>
                ))}
            </ImageList>

          </div>
        </div>
      </div>

      
    </Layout>
  );
};

const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
    #   allStrapiArticle {
    #     edges {
    #       node {
    #         strapiId
    #         slug
    #         title
    #         category {
    #           name
    #         }
    #         image {
    #           localFile {
    #             childImageSharp {
    #               gatsbyImageData(width: 800, height: 500)
    #             }
    #           }
    #         }
    #         author {
    #           name
    #           picture {
    #             localFile {
    #               childImageSharp {
    #                 gatsbyImageData(width: 30, height: 30)
    #               }
    #             }
    #           }
    #         }
    #       }
    #     }
    #   }
  }
`;

export default IndexPage;
