import React, { useEffect, useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { navigate } from "gatsby";
import styled, { keyframes } from "styled-components";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const moveVertically = (y) => keyframes`
    0% {
        transform : translate(0px) 
    }
    100% {
        transform : translate(${2*y/3}px)
    }
`;
const SlideAnimation = styled.div`
  animation: ${(props) => moveVertically(props.y)} ${(props) => props.time}s
    linear infinite;
`;

export function FlatImageListSlide({ data = itemData }) {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };
  

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  return (
    <div
      style={{
        overflowY: "hidden",
        overflowX: "hidden",
      }}
    >
      <SlideAnimation
        y={(screenSize.dynamicWidth - 100) * data.length * -1}
        time={100}
        style={{
          width: (screenSize.dynamicWidth - 100) * data.length,
          height: screenSize.dynamicWidth * (7 / 10) - 100,
          display: "flex",
          flexDirection: "row",
        }}
      >
        {data.map((item, i) => (
          <div
            key={`${item.img || item.src}+${i}`}
            style={{
              width: screenSize.dynamicWidth - 100,
              height: screenSize.dynamicWidth * (7 / 10) - 100,
            }}
          >
            <img
              {...srcset(item.img || item.src, 170, 1, 1)}
              alt={item.title || item.id}
              style={{
                width: screenSize.dynamicWidth - 100,
                height: screenSize.dynamicWidth * (7 / 10) - 100,
                marginRight: 5,
              }}
              loading="lazy"
            />
          </div>
        ))}
      </SlideAnimation>
    </div>
  );
}
export function FlatImageList({ data = itemData }) {
  return (
    <div
      style={{
        overflowY: "scroll",
      }}
    >
      <ImageList sx={{ width: 2000, height: 400 }} cols={5} gap={2}>
        {data.map((item, i) => (
          <ImageListItem
            key={`${item.img || item.src}+${i}`}
            cols={1}
            rows={1}
            onClick={() => navigate(`/app/recipe/` + item.id)}
          >
            <img
              {...srcset(item.img || item.src, 170, 1, 1)}
              alt={item.title || item.id}
              style={{
                width: 500,
                height: 400,
                marginRight: 10,
              }}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}

export default function QuiltedImageList({ data = itemData }) {
  return (
    <ImageList
      sx={{ width: 500, height: 450 }}
      // variant="quilted"
      variant="masonry"
      cols={4}
      rowHeight={170}
    >
      {data.map((item, i) => (
        <ImageListItem
          key={`${item.img.src}+${i}`}
          cols={item.cols || 1}
          rows={item.rows || 1}
          onClick={() => navigate(`/app/recipe/` + item.img.id)}
        >
          <img
            {...srcset(item.img.src, 170, item.rows, item.cols)}
            alt={item.title || item.img.id}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

export const itemData = [
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: "Breakfast",
    rows: 2,
    cols: 2,
  },
  {
    img:
      "https://www.gonenyoresel.com/UserFiles/Fotograflar/org/219-koy-kirmasi-yesil-zeytin-zeytin-zeytinkoytipiyesil.jpg",
    title: "Burger",
  },

  {
    img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
    title: "Tomato basil",
  },
  {
    img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    title: "Coffee",
    cols: 2,
  },
  {
    img:
      "https://www.ciftliktenkapima.com/image/cache/catalog/peynir/koyun-beyaz-peynir-2480x1772.jpg",
    title: "peynir",
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
    title: "Honey",
    author: "@arwinneil",
    rows: 2,
    cols: 2,
  },
  {
    img:
      "https://im.haberturk.com/2020/10/30/ver1604019387/2852963_414x414.jpg",
    title: "Basketball",
  },
  {
    img:
      "https://upload.wikimedia.org/wikipedia/commons/7/73/Nutella_for_breakfast_-_Flickr_-_love.jsc.jpg",
    title: "Fern",
  },
];
